@import "src/_variables.scss";

.listeUniteEnveloppe {
  padding: 20px;
  max-height: 580px;
  overflow: auto;
}
.listeUniteEnveloppe table th,
.listeUniteEnveloppe table td {
  text-align: center;
}
.listeUniteEnveloppe .divider {
  border-left: 1px solid $border-color;
  padding: 0;
  width: 0px;
}
.listeUniteEnveloppe .table-footer td {
  font-weight: bold;
  color: $base-color;
}

@import "src/_variables.scss";

.commentaire {
  margin-top: 5px;
  padding: 5px 10px;
}
.commentaire .date {
  float: right;
}
.commentaire h3 {
  color: $background-color;
  margin: 5px;
}
.commentaire h3 > svg {
  margin-right: 5px;
  transform: rotate(180deg);
  vertical-align: middle;
}
.commentaire .entete {
  margin: 2px;
}
.commentaire .entete dt,
.commentaire .entete dd {
  font-size: $font-size-small;
}
.commentaire .contenu {
  background-color: $background-color-light;
  margin: 2px 5px;
  padding: 5px;
}

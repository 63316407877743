@import "src/_variables.scss";

.pageSize {
  text-align: left;
  float: left;
  padding-left: 30px;
}
.pageSize label {
  display: inline-block;
  color: $base-color;
  margin-right: 5px;
  vertical-align: sub;
  padding-left: 10px;
}
.pageSize > div {
  vertical-align: middle;
}
.pageSize label span {
  font-weight: 600;
}



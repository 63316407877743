@import "src/_variables.scss";

.commentaireListe {
  margin-left: 10%;
  display: block;
  overflow: auto;
  text-align: left;
}
.commentaireListe .content {
  margin-top: 0;
  max-width: 700px;
}
.commentaireListe h2 {
  margin: 0 10px;
  display: block;
  text-align: left;
}
.commentaireListe .content button {
  font-size: $font-size-small;
}
.commentaireListe .content .completer {
  margin: 5px 5%;
}
.commentaireListe .content .ajouter {
  margin: 20px 0;
}

.commentaireListe .creationCommentaire {
  margin-top: 5px;
  padding: 10px;
}

@import "src/_variables.scss";

.topheader .selection-express {
  margin-right: 20px;
  margin-top: 10px;
}
.topheader .selection-express label {
  color: $header-text-color;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
}
.topheader .selection-express #input-selection-express {
  width: 150px;
  margin: 0px;
  @extend .input;
  border-radius: 4px 0 0 4px;
}
.topheader .selection-express .inputSelectionExpress {
  display: flex;
  padding: 0;
  border-radius: 4px;
}
.topheader .selection-express button {
  background-color: $background-color-light;
  border-radius: 0 4px 4px 0;
  padding: 5px;
  color: $header-text-color;
}
.suggestions {
  z-index: 99;
}
.suggestions ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 99;
}
.suggestions li div {
  display: block;
  text-align: right;
}
.suggestions li span {
  float: left;
}
.suggestions li button {
  padding: 0;
}

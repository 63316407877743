@import "src/_variables.scss";
.ligneEnveloppe a {
  text-decoration: none;
  color: $background-color;
}
.ligneEnveloppe:nth-child(2n + 1) {
  background-color: $background-color-light;
}
.AnyClassForContainer {
  position: fixed;
  right: 50px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  padding: 10px;
}

.AnyClassForTransition {
  right: 20px;
}

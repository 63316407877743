@import "src/_variables.scss";
.divnumpage {
  float :right;
}
.numPage {
  text-align: right;
  padding: 0;
}
.numPage li {
  display: inline;
  padding: 5px;
}
.numPage a {
  padding: 5px 10px;
  text-decoration: none;
  color: $base-color;
}
.numPage li.active a {
  background-color: $background-color;
  color: $header-text-color;
}
.numPage li.notActive a:hover {
  background-color: $background-color-light;
  color: $header-text-color;
}

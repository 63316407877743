@import "src/_variables.scss";

.infoFiltres {
  font-size: $font-size-small;
  background-color: $background-color-light;
  overflow: auto;
  text-align: left;
  margin: 10px;
  padding: 5px;
}
.infoFiltres > div {
  display: inline;
  margin: 0 10px;
}
.infoFiltres > div:first-child {
  float: right;
}
.infoFiltres > div > div {
  margin: 5px;
}

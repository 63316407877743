@import "src/_variables.scss";
.ligneFiche a {
  text-decoration: none;
  color: $background-color;
}
.ligneFiche:nth-child(2n + 1) {
  background-color: $background-color-light;
}
.ligneFiche td {
  padding: 8px;
}

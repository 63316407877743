@import "src/_variables.scss";

.ScrollFiltres {
  max-height: 800px;
}

.contenuListFiltre > div {
  display: block;
  width: 100%;
}
.contenuFiltre li {
  border-bottom: 1px solid $border-color;
  text-align: left;
  cursor: pointer;
  padding-left: 10%;
  font-size: 1vw;
}

@media screen and (min-width: 1650px) {
  .contenuFiltre li {
    padding-left: 30px;
    font-size: inherit;
  }
}
@media screen and (min-width: 1200px) {
  .contenuFiltre li {
    font-size: 0.7vw;
  }
}
.contenuFiltre li.selected {
  background-color: $background-color-light;
}

@import "src/_variables.scss";
.actionOperation li {
  vertical-align: text-top;
  list-style: none;
  display: inline-table;
  text-align: center;
  width: 25%;
}
.actionOperation li button {
  padding: 1px;
}
.actionOperation li span {
  display: block;
  font-size: $font-size-small;
  font-weight: bold;
  padding: 1px;
}

@import "src/_variables.scss";

.topheader {
  background-color: $background-color;
  padding: 10px;
  overflow: auto;
  color: $header-text-color;
  text-align: left;
}
@media (max-height: 700px) {
  .topheader {
    padding: 2px;
  }
}
.about {
  margin-right: 10px;
}

.bloc-gauche {
  float: left;
}

.environnement {
  font-weight: bold;
  text-align: center;
}

.topheader .bloc-droite {
  float: right;
}
.topheader .bloc-droite div {
  display: inline-block;
}
.topheader img {
  max-height: 50px;
}
.topheader .logoCitrus {
  text-decoration: none;
  color: $header-text-color;
  margin-right: 30px;
  vertical-align: text-top;
}
.topheader .logoCitrus span,
.topheader .logoCitrus svg {
  height: 30px;
  margin-right: 5px;
  font-size: 1.75rem !important;
  vertical-align: middle;
}
.topheader .logoCitrus img path {
  fill: $header-text-color;
}

.topheader .boutonCreation {
  @extend .input;
  padding: 12px;
  background-color: $background-color-light;
  text-decoration: none;
  color: $background-color;
}
.topheader .boutonCreation span {
  vertical-align: middle;
}
.topheader .boutonCreation svg {
  height: 50px;
  margin-right: 5px;
  font-size: 1.75rem !important;
  vertical-align: middle;
}

input::placeholder {
  font-style:italic;
}


@import "src/_variables.scss";

#filtres .titreFiltre {
  background-color: $background-color-filter;
  margin: 0;
  min-height: 0;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid $border-color;
}
#filtres h3 svg {
  color: $base-color-light;
  margin: 0 5px;
}
#filtres h3 span,
#filtres h3 svg {
  vertical-align: middle;
}
#filtres .titreFiltre svg {
  float: right;
}
#filtres .titreFiltre span {
  margin-left: 12px;
  vertical-align: sub;
}
#filtres .filtre {
  margin: 0;
  text-align: left;
}
#filtres .titreFiltre > div {
  margin: 6px 0;
  display: block;
}
.contenuFiltre {
  background-color: $background-color-filter-light;
  padding: 0 !important;
  display: block !important;
}

@import "src/_variables.scss";

.subheader {
  background-color: $background-color-light !important;
  padding: 4px;
  margin: 0;
  text-align: right;
}
.subheader .prec {
  color: $background-color;
  float: left;
  padding: 0;
  margin-right: 10px;
}

.userName svg {
  font-size: 1.4rem;
  margin-left:0.3rem;
  margin-bottom: 0.1em;
}

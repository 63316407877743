@import "src/_variables.scss";

.exportCSV {
  text-align: right;
  margin: 0 5%;
  text-decoration: none;
  color: $background-color;
}
.exportCSV svg {
  vertical-align: bottom;
}

@import "src/_variables.scss";

.subheader {
  border-radius: 0px !important;
}
.subheader nav {
  float: left;
}
.subheader nav a {
  color: $background-color;
  text-decoration: none;
}
.subheader nav .pageTitle {
  font-weight: bold;
  color: $base-color-light;
}

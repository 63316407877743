@import "src/_variables.scss";
body {
  overflow: hidden;
}
main {
  text-align: center;
  color: $base-color;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
header {
  z-index: 2;
}
.block-main {
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.block-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.block-content > div {
  padding: 10px;
}
@media (max-height: 700px) {
  .block-content > div {
    padding: 0 10px;
    margin: 2px;
  }
}
.block-main > .block-gauche {
  width: $width-left-block;
  font-size: $font-size-small;
  height: 100vh;
  background-color: $background-color-filter;
  border-right: 1px solid $border-color;
  z-index: -1;
}

.block-gauche h3 {
  text-transform: uppercase;
  font-size: $font-size-small;
  text-align: left;
  margin-left: 10px;
}
.listeRecherche {
  margin-top: 2px;
  overflow: auto;
}
.infoBulle {
  z-index: 4;
}
.infoBulle > div {
  background-color: $background-color;
  font-size: $font-size-small;
}
.info {
  font-style: italic;
  font-size: $font-size-small;
}

.entete dt,
.entete dd {
  display: inline-block;
  font-size: $font-size-medium;
  margin: 2px;
}
.entete dd {
  padding: 5px;
  font-weight: bold;
}
.entete dd:not(:last-child) {
  border-right: 1px solid $border-color;
}

.entete .commentaireTexte {
  vertical-align: top;
  font-size: $font-size-small;
}

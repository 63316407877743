@import "src/_variables.scss";

.modificationOperation {
  z-index: 2;
}

.boutonModifOp button {
  max-width: 600px;
}

.entete {
  margin-top: 10px;
  margin-bottom: 0px;
}


@import "src/_variables.scss";
.operation {
  text-align: left;
  margin: 10px;
}
@media (max-height: 700px) {
  .operation {
    margin: 0;
  }
}
.operation .typeOperation {
  text-align: center;
  margin-top: 16px;
  padding-right: 5px;
  font-size: 0.95rem;
}

.operation .typeOperation span,
.operation .typeOperation > div {
  display: block;
  margin-top: 0px;
}

.operation .typeOperation span {
  font-weight: bold;
}

.operation .typeOperation > span  {
  color: $operation-color;
}

.operation .typeOperation dl {
  text-align: center;
  margin: 0px;
}

.operation .titre {
  text-align: center;
  padding: 7px;
  font-size: $font-size-small;
  font-weight: bold;
}

.operation .unite {
  padding-left: 10px;
}

.operation dl {
  text-align: left;
  margin-right: 2px;
}
.operation dd {
  font-weight: bold;
  margin-left: 3px;
}
.operation dt,
.operation dd {
  display: inline-block;
  vertical-align:middle;
  font-size: $font-size-small;
}
.operation a {
  text-decoration: none;
  color: $background-color;
}

.operation dd input {
  font-size: $font-size-medium;
  padding: 0px;
}

.operation button {
  padding: 0px;
}
@import "src/_variables.scss";

.lienmenu {
  text-decoration: none;
  color: inherit;
}

.topheader .menu {
  display: inline-block;
  margin: 0;
  background-color: $background-color;
  border: none;
  padding: 0 10px;
  overflow: auto;
  color: $header-text-color;
  text-transform: none;
  font-size: 1rem;
  vertical-align: top;
}
.rc-menu-horizontal > .rc-menu-submenu {
  border: none;
}
.menu .rc-menu-submenu-active,
.menu .rc-menu-item-active,
.menu .rc-menu-submenu-active > .rc-menu-submenu-title,
.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: $background-color-light;
  color: $background-color;
  border: none;
}
.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title,
.rc-menu-vertical > .rc-menu-item {
  background-color: $header-text-color;
  z-index: 99;
}
.rc-menu > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 15px 26px 15px 22px;
}
.rc-menu-vertical .rc-menu-submenu-arrow {
  right: 10px;
}
.rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px/1;
  font-family: FontAwesome,Serif;
  font-size: inherit;
  vertical-align: middle;
  position: absolute;
  right: 8px;
}
.rc-menu-submenu-arrow:before {
  content: "\f0dd";
}
.rc-menu-submenu-selected,
.rc-menu-item-selected {
  color: $background-color;
  background-color: $header-text-color;
}

@import "src/_variables.scss";
.filtresCommentaire {
  width: 25% !important;
  background-color: $background-color-filter;
}
.filtresCommentaire ul {
  padding: 0;
}
.filtresCommentaire ul li {
  display: block;
  overflow: hidden;
}
.filtresCommentaire ul li span {
  vertical-align: sub;
}
.filtresCommentaire ul .nbCommentairesIcone {
  float: right;
}

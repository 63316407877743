.creation {
  margin-left: 10%;
  display: block;
  overflow: auto;
  text-align: left;
}
.creation .content {
  margin-top: 0;
  max-width: 700px;
}
.creation h2 {
  margin: 0 10px;
  display: block;
}
.creation h3 {
  margin-top: 50px;
}
.creation .content .field {
  margin-left: 15px;
}
.creation button {
  text-align: left;
}
.creation .content svg {
  vertical-align: bottom;
  margin-bottom: 10px;
  color: forestgreen;
}

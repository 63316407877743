$base-color: black;
$base-color-light: rgba($base-color, 0.55);
$background-color: #3f51b5;
$primary: $background-color;
$operation-color: #3f51b5;
$background-color-light: saturate(lighten($background-color, 40%), 20%);
$border-color: SILVER;
$header-text-color: WHITE;
$background-color-filter: GAINSBORO;
$background-color-filter-light: adjust-color(
  $background-color-filter,
  $lightness: 8%
);
$width-left-block: 16%;

$font-size-small: 0.8rem;
$font-size-medium: 1rem;

:export {
  /* stylelint-disable property-no-unknown */
  primary: $primary;
  /* stylelint-enable */
}

.shadow {
  box-shadow: 1px 1px 3px $border-color;
}
.input {
  border-radius: 4px;
  background-color: white;
  border: 1px solid $border-color;
  padding: 5px 12px;
}
